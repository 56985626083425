<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Tarifas</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Tarifas</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal_form_tarifas_export"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="create()"
                        v-if="
                          $store.getters.can('hidrocarburos.tarifas.create')
                        "
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  id="contratos"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <!---Inicio Input Filtro Radicado---->
                      <th>
                        Radicado RNDC
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.radicado"
                          @input="getIndex()"
                        />
                      </th>
                      <!---Inicio Input Filtro Nombre---->
                      <th>
                        Nombre
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.nombre"
                          @input="getIndex()"
                        />
                      </th>
                      <!---Inicio Input Filtro Tarifa --->
                      <th>
                        Tarifa
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.nu_tarifa"
                          @input="getIndex()"
                        />
                      </th>
                      <!---Inicio Select Filtro Tipo de Vehículo --->
                      <th>
                        Tipo de vehículo
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="tip_vehi_id"
                          placeholder="Tipo de Vehículo"
                          label="nombre"
                          :options="listasForms.tipos_vehiculo"
                          @search="buscarTiposvehi"
                          @input="getIndex()"
                        >
                        </v-select>
                      </th>
                      <!---Inicio Input Filtro Radicado---->
                      <th>
                        Producto Líquido
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.producto_liquido"
                          @input="getIndex()"
                        />
                      </th>
                      <!---Inicio Select Filtro Ruta --->
                      <th>
                        Ruta
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="ruta_id"
                          placeholder="Ruta"
                          label="nombre"
                          :options="listasForms.rutas"
                          @search="buscarRuta"
                          @input="getIndex()"
                        >
                        </v-select>
                      </th>
                      <th style="text-align: center">Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(tarifa, index) in tarifas.data" :key="index">
                      <td class="text-center">
                        <div v-if="tarifa.detalles_tarifa.length > 0">
                          <div v-if="tarifa.detalles_tarifa[0].radicado">
                            {{ tarifa.detalles_tarifa[0].radicado }}
                          </div>
                          <div v-else>
                            <span class="badge badge-secondary">
                              Sin Asignar
                            </span>
                          </div>
                        </div>
                        <div v-else>
                          <span class="badge badge-secondary">
                            Sin Asignar
                          </span>
                        </div>
                      </td>
                      <td>{{ tarifa.nombre }}</td>
                      <td class="text-center">
                        <div v-if="tarifa.detalles_tarifa.length > 0">
                          <div v-if="tarifa.detalles_tarifa[0].tarifa">
                            {{ tarifa.detalles_tarifa[0].tarifa }}
                          </div>
                          <div v-else>
                            <span class="badge badge-secondary">
                              Sin Asignar
                            </span>
                          </div>
                        </div>
                        <div v-else>
                          <span class="badge badge-secondary">
                            Sin Asignar
                          </span>
                        </div>
                      </td>
                      <td>{{ tarifa.tipo_vehiculo.nombre }}</td>
                      <td v-if="tarifa.producto_liquido != null">
                        {{ tarifa.producto_liquido.nombre }}
                      </td>
                      <td v-else></td>
                      <td>{{ tarifa.ruta.nombre }}</td>
                      <td style="width: 50px">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            v-if="
                              $store.getters.can('hidrocarburos.tarifas.show')
                            "
                            @click="edit(tarifa)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-if="
                              $store.getters.can('hidrocarburos.tarifas.delete')
                            "
                            @click="destroy(tarifa.id)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="tarifas.total">
                  <p>
                    Mostrando del <b>{{ tarifas.from }}</b> al
                    <b>{{ tarifas.to }}</b> de un total:
                    <b>{{ tarifas.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="tarifas"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <TarifasExport ref="TarifasExport" />
  </div>
</template>

<script>
import vSelect from "vue-select";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import TarifasExport from "./TarifasExport";

export default {
  name: "TarifasIndex",
  components: {
    pagination,
    vSelect,
    TarifasExport,
  },
  data() {
    return {
      nu_tarifa: null,
      ruta_id: null,
      tip_vehi_id: null,
      filtros: {
        radicado: null,
        nombre: null,
        nu_tarifa: null,
        tip_vehi_id: null,
        producto_liquido: null,
        ruta_id: null,
      },
      tarifas: {},
      listasForms: {
        tipos_vehiculo: [],
        rutas: [],
      },
    };
  },
  methods: {
    getIndex(page = 1) {
      this.filtros.ruta_id = null;
      if (this.ruta_id) {
        this.filtros.ruta_id = this.ruta_id.id;
      }

      this.filtros.tip_vehi_id = null;
      if (this.tip_vehi_id) {
        this.filtros.tip_vehi_id = this.tip_vehi_id.id;
      }
      axios
        .get("/api/hidrocarburos/tarifas?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.tarifas = response.data;
        });
    },

    destroy(idTarifa) {
      this.$swal({
        title: "Esta seguro de eliminar esta Tarifa?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios.delete("/api/hidrocarburos/tarifas/" + idTarifa).then(() => {
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se elimino la Tarifa exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          });
        }
      });
    },

    buscarTiposvehi(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/tiposVehiculos/lista?nombre=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.listasForms.tipos_vehiculo = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarRuta(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/rutas/lista?nombre=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.listasForms.rutas = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    create() {
      return this.$router.push({
        name: "/Hidrocarburos/TarifasForm",
        params: { accion: "Crear", id: null },
      });
    },

    edit(tarifa) {
      return this.$router.push({
        name: "/Hidrocarburos/TarifasForm",
        params: { accion: "Editar", data_edit: tarifa, id: tarifa.id },
      });
    },
    getMsg() {
      if (this.$route.params.msg) {
        this.$swal({
          icon: "success",
          title: this.$route.params.msg,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },
  },
  mounted() {
    this.getIndex();
    this.getMsg();
  },
};
</script>
